import React from 'react';
import { Link } from 'react-router-dom';
import Carousel from '../../components/Carousel/Carousel.component';
import './HomePage.styles.scss';

const HomePage = ({ handleServiceItem }) => (
    <div className="homepage-container">
        <header className="masthead">
            <Carousel />
            <div className="container">
                <div className="intro-text">
                    <div className="main-head-container">
                        <div className="intro-heading text-uppercase">
                            <h1>align infra</h1>
                        </div>
                        <div className="underline"></div>
                        <div className="intro-lead-in">
                            <p>Engineering Solutions</p>
                        </div>
                        <a className="banner-btn" href="#services">Tell Me More</a>
                    </div>
                    {/* <a className="tellme btn btn-primary btn-xl text-uppercase js-scroll-trigger" href="#services">Tell Me More</a> */}
                </div>
            </div>
        </header>

        <section className="page-section" id="services">
            <div className="container-fluid" id="service-container">
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <h2 className="section-heading text-uppercase">Services</h2>
                        <h3 className="section-subheading text-muted">Experienced, Efficient & Cost Effective</h3>
                    </div>
                </div>
                <div className="row text-center">
                    <Link to='services' className="col-sm-6 col-md-4 col-xl-3" onClick={() => handleServiceItem(1)}>
                        <span className="fa-stack fa-4x">
                            <i className="fa fa-circle fa-stack-2x text-primary"></i>
                            <i className=" home-icon material-icons fa-stack-1x fa-inverse">emoji_objects</i>
                        </span>
                        <h4 className="service-heading">Detailed Engineering solutions</h4>
                        <p className="text-muted grow">Civil Infrastructure, Design and Drafting Services, Piping, Architectural Solutions, Instrumentation, Mechanical Services, As Built Documentation, Structural Services, CAD conversion, Electrical Services</p>
                    </Link>
                    <Link to='services' className="col-sm-6 col-md-4 col-xl-3" onClick={() => handleServiceItem(2)}>
                        <span className="fa-stack fa-4x">
                            <i className="fa fa-circle fa-stack-2x text-primary"></i>
                            <i className=" home-icon fa fa-laptop fa-stack-1x fa-inverse"></i>
                        </span>
                        <h4 className="service-heading">Building Information Modeling (BIM)</h4>
                        <p className="text-muted grow">Preparation of BIM Model, Visualization of BIM Model, Analyze, Detect and resolve clash, Model based Quantity Take-off, 
                        3D reinforcement detailing for complex structures, Fabrication/Shop Drawings, 4D Phasing – linking the 3D Model with the Project Schedules, 
                        COBie enrichment, As-built Modeling works – Generation / 3D Models using Point Cloud / laser scan data, Audit Managers/BIM Managers.</p>
                    </Link>
                    <Link to='services' className="col-sm-6 col-md-4 col-xl-3" onClick={() => handleServiceItem(3)}>
                        <span className="fa-stack fa-4x">
                            <i className="fa fa-circle fa-stack-2x text-primary"></i>
                            <i className=" home-icon material-icons fa-stack-1x fa-inverse">extension</i>
                        </span>
                        <h4 className="service-heading">Project Management Services</h4>
                        <p className="text-muted grow">Preparation of Project Report, Design Report etc., Quantity take-offs, BOQ Preparation and evaluation, Pre-Bid services (In association with Universities and
                        other experts) Site assessment & surveys, Preliminary planning, Preparation of method statements & procedures, Evaluation of technologies, System studies, 
                        Feasibility & detailed project reports, Project management services like, Bid evaluation & Coordination of services, Peer review of pre and post bid documentation.,
                        Evaluation of Prequalification, Preparation of technical bids etc., Quality control and technical support</p>
                    </Link>
                    <Link to='services' className="col-sm-6 col-md-4 col-xl-3" onClick={() => handleServiceItem(4)}>
                        <span className="fa-stack fa-4x">
                            <i className="fa fa-circle fa-stack-2x text-primary"></i>
                            <i className=" home-icon material-icons fa-stack-1x fa-inverse">traffic</i>
                        </span>
                        <h4 className="service-heading">Traffic Management</h4>
                        <p className="text-muted grow">Realistic and Efficient solutions to traffic engineering and planning, Cost Effective traffic management solutions to construction
                        companies, road maintenance companies, builders, traffic control companies etc., We Provide personalized and customized plan to suit your workplace, 
                        activities and traffic situations, We build up traffic plans to ensure maximum efficiency while maintaining compliance standards & highest safety.</p>
                    </Link>
                    <Link to='services' className="col-sm-6 col-md-4 col-xl-3" onClick={() => handleServiceItem(5)}>
                        <span className="fa-stack fa-4x">
                            <i className="fa fa-circle fa-stack-2x text-primary"></i>
                            <i className=" home-icon fa fa-lock fa-stack-1x fa-inverse"></i>
                        </span>
                        <h4 className="service-heading grow">Underground utility survey</h4>
                        <p className="text-muted">Underground utility works comprises desktop utility record search, site reconnaissance,detection and verification survey</p>
                    </Link>
                </div>
            </div>
        </section>
    </div>
);

export default HomePage;