import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../../assets/img/logofinal.svg';
import './Logobar.styles.scss';

const Logobar = () =>(
    <div className="logobar-container">
        <Link className="home-link" to="/">
            <Logo id="align-infra-logo" alt="align-infra-logo"/>
            <div className="text-container">
                <span id="main-head-align">ALIGN INFRA</span>
                <div></div>
                <span id="sub-head-building">building links for the Future</span>
            </div>
        </Link>
    </div>
);

export default Logobar;