import React, { useState, useRef } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import Logobar from './components/Logobar/Logobar.component';
import Navbar from './components/Navbar/Navbar.component';
import HomePage from './pages/HomePage/HomePage.component';
import About from './pages/About/About.component';
import Service from './pages/ServicePage/Services.component';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy.component';
import Footer from './components/Footer/Footer.component';
import Career from './pages/Career/Career.component';
import { useIsInViewport } from './utils/CustomHooks';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './assets/css/agency.min.css';
import './App.css';

function App() {
  const [serviceItem, setServiceItem] = useState(0);
  const pageEndRef = useRef(null);
  const contactRef = useRef(null);
  const isContactDivInView = useIsInViewport(contactRef);

  const handleServiceItem = (num) => {
    setServiceItem(num);
  };

  const scrolltoBottom = () => {
    pageEndRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const gotoContact = () => {
    if (contactRef.current) {
      const offset = contactRef.current.offsetTop - 120;
      window.scrollTo({
        top: offset,
        behavior: 'smooth'
      });
    }
  };

  return (
    <div className='App'>
      <Logobar />
      <Navbar
        handleServiceItem={handleServiceItem}
        scrolltoBottom={scrolltoBottom}
        gotoContact={gotoContact}
        isContactDivInView={isContactDivInView}
      />
      <Switch>
        <Route exact path='/' render={() => <HomePage handleServiceItem={handleServiceItem} />} />
        <Route path='/about' component={About} />
        <Route path='/services' component={() => <Service itemNo={serviceItem} />} />
        <Route path='/careers' component={Career} />
        <Route path='/privacy' component={PrivacyPolicy} />
      </Switch>
      <Footer ref={contactRef} />
      <div ref={pageEndRef} />
      <Toaster />
    </div>
  );
}

export default App;
