/**
 * The normalFieldValidation function checks if a text is empty and returns an error message if it is.
 * @param fieldName - The fieldName parameter is a string that represents the name of the field being
 * validated.
 * @param text - The `text` parameter is the input value that needs to be validated. It is a string
 * that represents the text entered in a field.
 * @returns an error message if the text is empty or whitespace, otherwise it returns an empty string.
 */
export function normalFieldValidation(fieldName, text) {
  if(text.trim().length === 0) return `${fieldName} is required`;
  else return '';
}

/**
 * The function checks if an email is valid or not.
 * @param email - The `email` parameter is a string that represents an email address.
 * @returns a string. If the email is empty, it returns 'Email is required'. If the email is valid, it
 * returns an empty string. If the email is invalid, it returns 'Invalid email'.
 */
export function emailValidation (email) {
  if (email.length === 0) {
    return 'Email is required';
  } else if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return '';
  }
  return 'Invalid email';
};