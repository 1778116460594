export const Data = [
    {
        heading: "Detailed engineering",
        subtitles: [
            { id: 0, title: "Civil Infrastructure", description: "" },
            { id: 1, title: "Design and Drafting Services", description: "Develop designs to facilitate easy execution of the project." },
            { id: 2, title: "Piping", description: "" },
            { id: 3, title: "Architectural Solutions", description: "" },
            { id: 4, title: "Instrumentation", description: "" },
            { id: 5, title: "Mechanical Services", description: "" },
            { id: 6, title: "As Built Documentation", description: "" },
            { id: 7, title: "Structural Services", description: "" },
            { id: 8, title: "CAD conversion", description: "" },
            { id: 9, title: "Electrical Services", description: "" }
        ],
        details: [
            {
                title: "Civil Infrastructure",
                listitems: [
                    "Reinforced concrete framed structures",
                    "Foundations for Structures, Equipment & Machinery",
                    "Concrete storage silos, Large diameter storage tank foundations",
                    "Retaining walls, Dyke walls and Boundary walls",
                    "Cable & Pipe trenches and tunnels",
                    "Road, Pavements, Grading plan and Drainage systems",
                    "Gates, Compound walls and Fencings",
                ]
            },
            {
                title: "Design and Drafting Services",
                listitems: [
                    "Site / On-site coordination",
                    "Constructability reviews and site surveys",
                    "Project proposals",
                    "Generating & resolving Design Review file and Review Comments.",
                    "Utilities drawings – Wet and dry utilities, Utilities clash detection drawings",
                    "Road drawings – Layouts, profile, details.",
                    "2D drafting and 3D Modelling of Plant on PDMS/PDS/AutoCAD 3D/SP3d.",
                    "Detailing structural drawings - Setting out & formwork drawings - Reinforcement - Optimized Bar bending schedules - Steel fabrication drawings",
                    "Project proposals",
                    "2D drafting and 3D Modelling of Plant on PDMS/PDS/AutoCAD 3D/SP3d.",
                    "Technical Manpower Supply for all Engineering Discipline.",
                    "Conversion PDF/TIFF formats to *.dwg format",
                    "Conversion of all paper-based source data including manual sketches, drawings, as-built drawings into intelligent applications."
                ]
            },
            {
                title: "Piping",
                listitems: [
                    "Performing Piping Studies.",
                    "Piping layout and profile drawings.",
                    "Stress Analysis of Piping Systems.",
                    "Preparation of Piping Material Specifications.",
                    "Preparation of Isometric drawings.",
                    "Nozzle Orientation, Platform and Support Cleat requirement drawings.",
                    "Development of Plot Plan, Key Plan, Equipment General Arrangement Drawings.",
                    "Preparation of Piping General Arrangement Drawings.",
                    "Material Take-Offs / Requisition for Piping & Specialty Items.",
                    "Pipeline Design & Preparation of Alignment Drawings.",
                ]
            },
            {
                title: "Architectural Solutions",
                listitems: [
                    "We provide architectural drawings for the Residential Buildings, Commercial Buildings, Train Stations & Airports,Hospitals, Hotels & Resorts, Schools & University, Industrial & Gas Stations, Historical Monuments, etc.",
                    "Drawings includes 3D Views, Site Plan, Floor Plans, Interior Details, Roof Layout, Sections, Elevations, Door & Window Schedules ",
                ]
            },
            {
                title: "Instrumentation",
                listitems: [
                    "Input to P&ID’s",
                    "Instrument Index",
                    "Flow Calculations and Sizing of Control valves, Orifice plates & PSV",
                    "Layouts of Instrument location, cable etc and Installation drawings",
                    "Logic, Cause & Effects and Instrument Loop Diagrams",
                    "Instrument Data sheets and Requisitions",
                    "Instrument / JB Termination & Interconnection diagrams",
                    "Cable Schedules and MTO",
                    "DCS, SCADA, PLC I/O List",
                    "Instrument Hook-up drawings",
                ]
            },
            {
                title: "Mechanical Services",
                listitems: [
                    "Preparation of GA Drawing & Fabrication drawing.",
                    "Preparation of Mechanical DataSheet.",
                    "Providing Civil Loading details for Equipment",
                    "Vendor drawing checking & approval"
                ]
            },
            {
                title: "As Built Documentation",
                listitems: [
                    "Preparation of as-built drawings based on client’s inputs and actual site condition",
                    "To revamp the project for clients",
                    "Update Vendor/Manufacturer information in plant key drawings",
                    "Updating the drawings based on field mark-ups",
                    "Update existing drawings to reflect as-built conditions",
                    "Incorporation of Red mark-ups in drawings and documents",
                    "As-built for operation and maintenance assistance",
                    "Ensure drawings are consistent with Quality System Procedure.",
                    "Project completion report ",
                    "O&M manuals",
                    "As-built plant information to assist Operator Training System Vendors for generating software for the OTS of the plant to ease the start-up and operation",
                    "Update all documents like instrument specification sheet, equipment data sheets, wiring database to reflect as-built conditions",
                ]
            },
            {
                title: "Structural Services",
                listitems: [
                    "(includes Analysis, Design & Detailing of)",
                    "Roads & Bridges",
                    "Tunnels & Underpass",
                    "Shafts & Manholes",
                    "Steel framed buildings and Superstructures",
                    "Turbine houses, workshop buildings etc.",
                    "Switch yards and Transmission line towers",
                    "Pipe & cable rock structures, Conveyor galleries & Trestles",
                ]
            },
            {
                title: "CAD conversion",
                listitems: [
                    "Conversion of hard Copy / Images to Electronic format",
                    "Digitizing on MicroStation and Auto CADD",
                    "Migration/conversion of drawings from MicroStation, Auto CADD, scanned drawings to Smart Plant drawings",
                    "MicroStation / Auto CADD programs for generating design drawing of all disciplines (Mechanical, Instrumentation and Control, Structural, Civil, Architectural and Communication)",
                    "3D modeling utilizing Intergraph’s Plant Design System (PDS,SP3d) & Aveva (PDMS",
                ]
            },
            {
                title: "Electrical Services",
                listitems: [
                    "Concept & Load flow studies",
                    "Layouts for equipment, power, earthing, lighting, etc. and Installation drawings",
                    "Voltage drop, cable sizing calculations",
                    "Key SLD, SLD’s for all voltage levels",
                    "Selection of HV/LV Switchgear, MCC, Transformers, UPS & Hazardous area equipment",
                    "Interconnection diagram between various Equipment",
                    "Cable Schedules and BOM",
                    "DCS/SCADA I/O List",
                ]
            },
        ]
    },
    //second service
    {
        heading: "Buliding Information Modeling (BIM)",
        subtitles: [
            { id: 0, title: "Preparation of BIM Model for ", description: "" },
            { id: 1, title: "Visualization of BIM Model for", description: "" },
            { id: 2, title: "Fabrication/Shop Drawings includes" },
            { id: 3, title: "Model based Quantity Take-off", description: "" },
            { id: 4, title: "3D reinforcement detailing for complex structures", description: "" },
            { id: 5, title: "Analyze, Detect and resolve clash", description: "" },
            { id: 6, title: "4D Phasing – linking the 3D Model with the Project Schedules", description: "" },
            { id: 7, title: "COBie enrichment", description: "" },
            { id: 8, title: "As-built Modeling works – Generation / 3D Models using Point Cloud / laser scan data.", description: "" },
            { id: 9, title: " Audit Managers/BIM Managers.", description: "" },
        ],
        details: [
            {
                title: "Preparation of BIM Model for",
                listitems: [
                    "Infrastructure Modelling (all related to Transportation, Energy, Water management, Waste management etc.,)",
                    "Architectural Modelling including facades ",
                    "Structural Modelling ",
                    "MEP Modelling",
                ]
            },
            {
                title: "Visualization of BIM Model for",
                listitems: [
                    "Rendering",
                    "Walkthrough / Flythrough BIM Models",
                    "Modelling from digital mapping",
                    "Presentation Videos / Movies",
                    "Animation of Construction Sequences",
                ]
            },
            {
                title: "Fabrication/Shop Drawings includes",
                listitems: [
                    "Extraction of 2D drawings/ Information from BIM Models for the respective discipline.",
                    "2D Architectural drawings including fully co-ordination block work drawings",
                    "2D Concrete outline drawings",
                    "Rain screen cladding drawings ",
                ]
            },
            {
                title: "",
                listitems: []
            },
            {
                title: "",
                listitems: []
            },
            {
                title: "",
                listitems: []
            },
            {
                title: "",
                listitems: []
            },
            {
                title: "",
                listitems: []
            },
            {
                title: "",
                listitems: []
            },
            {
                title: "",
                listitems: []
            },


        ]
    },
    //service 3
    {
        heading: "Project Managment",
        subtitles: [
            { id: 0, title: "Preparation of Project Report, Design Report etc.", description: "" },
            { id: 1, title: "Quantity take-offs, BOQ Preparation and evaluation", description: "" },
            { id: 2, title: "Pre-Bid services (In association with Universities and other experts) Site assessment & surveys", description: "" },
            { id: 3, title: "Preliminary planning", description: "" },
            { id: 4, title: "Preparation of method statements & procedures ", description: "" },
            { id: 5, title: "Evaluation of technologies", description: "" },
            { id: 6, title: "System studies", description: "" },
            { id: 7, title: "Feasibility & detailed project reports", description: "" },
            { id: 8, title: "Project management services like, Bid evaluation & Coordination of services", description: "" },
            { id: 9, title: "Peer review of pre and post bid documentation.", description: "" },
            { id: 10, title: " Evaluation of Prequalification, Preparation of technical bids etc.", description: "" },
            { id: 11, title: "Quality control and technical support", description: "" },

        ],
        details: [
            {
                title: "",
                listitems: []
            },
            {
                title: "",
                listitems: []
            },
            {
                title: "",
                listitems: []
            },
            {
                title: "",
                listitems: []
            },
            {
                title: "",
                listitems: []
            },
            {
                title: "",
                listitems: []
            },
            {
                title: "",
                listitems: []
            },
            {
                title: "",
                listitems: []
            },
            {
                title: "",
                listitems: []
            },
            {
                title: "",
                listitems: []
            },
            {
                title: "",
                listitems: []
            },
            {
                title: "",
                listitems: []
            },
        ]
    },
    //service 4
    {
        heading: "Traffic Managment",
        subtitles: [
            { id: 0, title: "Realistic and Efficient solutions to traffic engineering and planning.", description: "" },
            { id: 1, title: "Cost Effective traffic management solutions to construction companies, road maintenance companies, builders, traffic control companies etc.", description: "" },
            { id: 2, title: "We Provide personalized and customized plan to suit your workplace, activities and traffic situations.", description: "" },
            { id: 3, title: "We build up traffic plans to ensure maximum efficiency while maintaining compliance standards & highest safety.", description: "" },
            { id: 4, title: "Our Core Capabilities Include:" },
        ],
        details: [
            {
                title: "",
                listitems: []
            },
            {
                title: "",
                listitems: []
            },
            {
                title: "",
                listitems: []
            },
            {
                title: "",
                listitems: []
            },
            {
                title: "Our Core Capabilities Include:",
                listitems: [
                    "On-site road safety auditing.",
                    "Concept and design of roads",
                    "Regulatory signage installation",
                    "Traffic management and planning",
                    "Risk assessments"
                ]
            },
        ]

    },
    //service 5
    {
        heading: "Underground Utility Survey",
        subtitles: [
            { id: 0, title: " Underground utility works comprises desktop utility record search, site reconnaissance, detection and verification survey", description: "" },
            { id: 1, title: " Our underground detection surveys include:", description: "" },
        ],
        details: [
            {
                title: "",
                listitems: []
            },
            {
                title: "Our underground detection surveys include:",
                listitems: [
                    "Reduced exposure to the buried hazards",
                    "Non-intrusive survey – minimizes disruption",
                    "Desk study/statutory service provider searches",
                    "Latest survey equipment and software ",
                    "Trained and experienced staff",
                    "Accurate record of underground utility depths and locations",
                ]
            },
        ]
    },
];