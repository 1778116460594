import React from 'react';
import './ServiceItem.styles.scss';
import { Data } from '../../pages/ServicePage/data';
import ServicePanel from '../ExpansionPanel/ServicePanel.component';

const ServiceItem = (item, index) => {
  let serviceData = [];
  if (item) {
    serviceData = item;

  } else {
    serviceData = Data[0];
  }
  return (
    <div className="service-item-main">
      <div className="title-main">
        <h2>{serviceData.item.heading}</h2>
      </div>
      <div className="service-details">
        <ServicePanel serviceDetails={serviceData.item} index={serviceData.index}></ServicePanel>
      </div>
    </div>
  );
}

export default ServiceItem;