import React from 'react';
import { Data } from './data';
import ServiceItem from '../../components/Cards/ServiceItem.component'
import './Services.styles.scss';

const Services = ({ itemNo }) => {

  let number = 0;
  if (itemNo !== undefined && itemNo > 1) {
    number = itemNo - 1;
  }

  return (
    <div className="m0-p0 services-container service-outer">
      <ServiceItem item={Data[number]} index={number}></ServiceItem>
    </div>
  );
}
export default Services;