import React from 'react';
import BG1 from '../../assets/img/home-background.jpg';
import BG2 from '../../assets/img/home-bg2.jpg';
import BG3 from '../../assets/img/home-bg3.jpg';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/scss/image-gallery.scss";
import './Carousel.styles.scss';
 
const images = [
    {
        original: BG1,
    },
    {
        original: BG2,
    },
    {
        original: BG3,
    }
];

const Carousel = () => (
    <div className="carousel-container">
        <ImageGallery 
            items={images} 
            showThumbnails={false} 
            showFullscreenButton={false} 
            showPlayButton={false} 
            showBullets={false} 
            autoPlay={true} 
            useBrowserFullscreen={false} 
            slideInterval={8000} 
            showNav={false}
        />
    </div>
);

export default Carousel;