import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import './NestedList.styles.scss';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

export default function NestedList({ handleClose }) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            className={classes.root}
        >
            <ListItem button onClick={handleClick} className="service-list">
                <ListItemIcon>
                    <span className="material-icons">settings</span>
                </ListItemIcon>
                <ListItemText primary="Services" />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <Link className="dropdown-link" to='/services'>
                        <ListItem button className={classes.nested}>
                            <ListItemText className="service-sublist"><p id="1" onClick={(e) => handleClose(e)}>Detailed Engineering Solutions</p></ListItemText>
                        </ListItem>
                        <ListItem button className={classes.nested}>
                            <ListItemText className="service-sublist"><p id="2" onClick={(e) => handleClose(e)}>Building Information Modeling</p></ListItemText>
                        </ListItem>
                        <ListItem button className={classes.nested}>
                            <ListItemText className="service-sublist"><p id="3" onClick={(e) => handleClose(e)}>Project Management Services</p></ListItemText>
                        </ListItem>
                        <ListItem button className={classes.nested}>
                            <ListItemText className="service-sublist"><p id="4" onClick={(e) => handleClose(e)}>Traffic Management</p></ListItemText>
                        </ListItem>
                        <ListItem button className={classes.nested}>
                            <ListItemText className="service-sublist"><p id="5" onClick={(e) => handleClose(e)}>Underground Utility Survey</p></ListItemText>
                        </ListItem>
                    </Link>
                </List>
            </Collapse>
        </List>
    );
}