import React from 'react';
import QualityManage from '../../assets/img/quality.PNG';
import WhyChoose from '../../assets/img/tree.PNG';
import Health from '../../assets/img/health.PNG';
import Environment from '../../assets/img/env.PNG';
import Safety from '../../assets/img/safety.PNG';
import Team from '../../assets/img/our-team.png';
import './About.css';

const About = () => (
  <div className='about-us-container'>
    <div className='container'>
      <div className='about'>
        <div className='row'>
          <div className='col-lg-12 text-center'>
            <h2 className='section-heading text-uppercase'>About Us</h2>
            <p>
              Align Infra is a Team of highly professional and experienced project managers,
              engineers and planners offering innovative engineering services catering client’s
              requirement with customised solutions. We are committed to provide best quality and
              utmost customer satisfaction. In addition to engineering services we also provide
              technical and managerial system support.
              <br />
              <br />
              Align Infra offers end to end engineering solutions like Detailed Design & Drafting
              Services for civil, structural, mechanical, electrical, Piping etc., BIM services,
              Project management services, traffic management through design conceptualization and
              construction documents with core focus on Infrastructure projects. The firm also
              offers construction management, value engineering studies, feasibility of real estate
              development and technical & financial evaluation of properties.
              <br />
              <br />
              We aim to see Align Infra as one of the leaders in modern concepts in Engineering
              Consulting.
            </p>
          </div>
        </div>
      </div>
    </div>
    <section className='page-section' id='about'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <ul className='timeline'>
              <li>
                <div className='timeline-image'>
                  <img className='rounded-circle img-fluid' src='assets/view.png' alt='' />
                </div>
                <div className='timeline-panel'>
                  <div className='timeline-heading'>
                    <h4 className='about-subheading'>VISION</h4>
                  </div>
                  <div className='timeline-body'>
                    <p className='text-muted'>
                      We plan to be a fortune 500 company with our highly professional and dedicated
                      team while providing world’s best service and engineering solutions.
                    </p>
                  </div>
                </div>
              </li>
              <li className='timeline-inverted'>
                <div className='timeline-image'>
                  <img className='rounded-circle img-fluid' src='assets/target.png' alt='' />
                </div>
                <div className='timeline-panel'>
                  <div className='timeline-heading'>
                    <h4 className='about-subheading'>MISSION</h4>
                  </div>
                  <div className='timeline-body'>
                    <p className='text-muted'>
                      We aim to serve across the globe with our end to end solutions for all
                      engineering solutions need. With our technical and managerial support, we
                      pledge to serve quality services to all project consultants and contractors.
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className='timeline-image'>
                  <img className='rounded-circle img-fluid' src='assets/diamond.png' alt='' />
                </div>
                <div className='timeline-panel'>
                  <div className='timeline-heading'>
                    <h4 className='about-subheading'>VALUES</h4>
                  </div>
                  <div className='timeline-body'>
                    <p className='text-muted'>
                      To follow ethical practices of Integrity, accountability, excellence,
                      innovation, sustainability, unity & delivery by leveraging our technological
                      and organizational innovation with exceptional human resources for sustainable
                      worldwide development.
                    </p>
                  </div>
                </div>
              </li>
              <li className='timeline-inverted'>
                <div className='timeline-image'>
                  <h5>
                    Be Part
                    <br />
                    Of Our
                    <br />
                    Story!
                  </h5>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <div className='container'>
      <div className='row'>
        <div className='col-lg-12'>
          <h2 className='choose-heading'>WHY CHOOSE US ?</h2>
        </div>
      </div>
      <div className='row justify-content-between why-choose-row'>
        <div className='col-md-12 col-lg-6'>
          <ul>
            <li className='list-head'>Proven Expertise</li>
            <p>
              Whether it is staging of work programs or the site works, Align Infra has proven
              expertise in designing and implementing effective solutions. It is accurately aligned
              according to your specifications.
            </p>
            <li className='list-head'>Transparency At Work</li>
            <p>
              We consider taking a balanced and ground-breaking approach to the problems. That's why
              we make sure to maintain transparency in dealing with all construction companies,
              builders, an event for councils or authorities.
            </p>
            <li className='list-head'>Highly-Skilled Professionals</li>
            <p>
              With our professional team of engineers, we work with detailed project programmes,
              management plans and implementing the same, while reducing costs and fulfilling
              client's requirements.
            </p>
            <li className='list-head'>Timely Completion of the Project</li>
            <p>
              By working hard in a smarter way and implementing modern methodologies, our team
              ensures timely completion of the project.
            </p>
          </ul>
        </div>
        <div className='col-md-12 col-lg-6'>
          <div className='why-choose-img-container'>
            <img className='why-choose-img' src={WhyChoose} />
          </div>
        </div>
      </div>
    </div>
    <div className='container'>
      <div className='row'>
        <div className='col-lg-12'>
          <h2 className='choose-heading'>QUALITY MANAGEMENT</h2>
        </div>
      </div>
      <div className='row justify-content-between quality-row'>
        <div className='col-md-12 col-lg-6'>
          <p className='quality-para'>
            We work with stable, trackable processes on the basis of an exacting management system.
            <br />
            <br />
            In order to continuously improve, we document our work, analyse workflows, and make the
            necessary adjustments. As part of this improvement process, tested and selected
            suggestions for changes are continuously added to our management system.
            <br />
            <br />
            Our client’s quality management specification requirements are taken into consideration.
            We work with them to systematically secure construction quality in order to mitigate
            defects raising in the first place.
          </p>
        </div>
        <div className='col-md-12 col-lg-5'>
          <div className='quality-image-container'>
            <img className='quality-image' src={QualityManage} />
          </div>
        </div>
      </div>
    </div>
    <div className='container health-container'>
      <div className='row'>
        <div className='col-lg-12'>
          <h2 className='choose-heading'>HEALTH, SAFETY & ENVIRONMENT</h2>
        </div>
      </div>
      <div className='row'>
        <div className='col-lg-12'>
          <p>
            Align Infra follows international best practices, as a means of promoting a positive and
            responsible attitude towards Health Safety and Environment issues on projects.
          </p>
        </div>
      </div>
      <div className='row justify-content-between health-row'>
        <div className='col-md-5 col-lg-3'>
          <div className='img-container'>
            <img src={Health} />
          </div>
          <h4>Health</h4>
          <p>
            Promoting and improving the health of our employees and all other workforce working
            behalf of Align Infra.
          </p>
        </div>
        <div className='col-md-5 col-lg-3'>
          <div className='img-container'>
            <img src={Safety} />
          </div>
          <h4>Safety</h4>
          <p>
            Ensuring that safety values are not compromised, personnel are protected and workplace
            is provided where people are able to work without being injured.
          </p>
        </div>
        <div className='col-md-5 col-lg-3 env-column'>
          <div className='img-container'>
            <img src={Environment} />
          </div>
          <h4>Environment</h4>
          <p>
            Promoting the efficient use of resources, reducing and preventing pollution and
            enhancing biodiversity protection.
          </p>
        </div>
      </div>
      <div className='row'>
        <div className='col-lg-12'>
          <p>
            Align Infra will ensure that all personnel are given adequate information, training and
            instruction regarding legal compliance and risk and where necessary enforce control
            measures.
          </p>
        </div>
      </div>
    </div>
    <div className='container team-container'>
      <div className='row'>
        <div className='col-lg-12'>
          <h2 className='choose-heading'>OUR TEAM</h2>
        </div>
      </div>
      <div className='row our-team-row'>
        <div className='col-lg-6'>
          <p className='team-para'>
            Our team is a group of motivated individuals working in synergy to achieve desired
            result while keeping our clients's satisfaction at center stage. Having specialized in
            engineering solutions like Detailed Design & Drafting Services for civil, structural,
            mechanical, electrical, Piping, BIM services, Project management services, traffic
            management, underground utility management with core focus on Infrastructure projects,
            we are chosen by clients for their excessively demanding projects under most pressing
            timelines.
          </p>
        </div>
        <div className='col-lg-5'>
          <div className='our-team-img-container'>
            <img className='our-team' src={Team} />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default About;
