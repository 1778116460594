import React, { useState } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { JobData } from './JobData';
import ControlledExpansionPanels from '../../components/ExpansionPanel/ExpansionPanel.component';
import './Career.styles.scss';

const Career = () => {
  const [fullname, setFullname] = useState('');
  const [dob, setDob] = useState();
  const [jobcategory, setJobcategory] = useState('');
  const [location, setLocation] = useState('');
  const [salary, setSalary] = useState('');
  const [mobileno, setMobileno] = useState('');
  const [email, setEmail] = useState('');
  const [paddress, setPaddress] = useState('');
  const [language, setLanguage] = useState('');
  const [proqualification, setProqualification] = useState('');
  const [addqualification, setAddqualification] = useState('');
  const [skills, setSkills] = useState('');
  const [proexp, setProexp] = useState('');
  const [otherexp, setOtherexp] = useState('');
  const [resume, setResume] = useState('');
  const [filename, setFilename] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [failed, setFailed] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const [fileValid, setFileValid] = useState(false);
  const supportedType = [
    'application/pdf',
    'image/jpeg',
    'image/png',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ];

  const setValidEmail = (email) => {
    setEmail(email);
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
      setEmailValid(true);
    } else {
      setEmailValid(false);
    }
  };
  const setResetForm = () => {
    setFullname('');
    setDob('');
    setJobcategory('');
    setLocation('');
    setSalary('');
    setMobileno('');
    setEmail('');
    setPaddress('');
    setLanguage('');
    setProqualification('');
    setAddqualification('');
    setSkills('');
    setProexp('');
    setOtherexp('');
    setResume(null);
    setFilename('');
  };
  const onFileChange = (event) => {
    let filename = event.target.files[0].name;

    if (
      supportedType.indexOf(event.target.files[0].type) > -1 &&
      event.target.files[0].size < 3147136
    ) {
      setFileValid(true);
      if (event.target.files && event.target.files[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          setResume(e.target.result);
          setFilename(filename);
        };
        reader.readAsDataURL(event.target.files[0]);
      }
    } else {
      setFileValid(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitting(true);
    var form = {
      fullname: fullname,
      dob: dob.toLocaleDateString(),
      jobcategory: jobcategory,
      location: location,
      salary: salary,
      mobileno: mobileno,
      email: email,
      paddress: paddress,
      language: language,
      proqualification: proqualification,
      addqualification: addqualification,
      skills: skills,
      proexp: proexp,
      otherexp: otherexp,
      resume: resume,
      filename: filename
    };
    axios
      .post('/submitcareer', form)
      .then((res) => {
        if (res.data.status === 200) {
          setResetForm();
          setSubmitted(true);
          setSubmitting(false);
          setFailed(false);
        } else {
          setSubmitted(false);
          setSubmitting(false);
          setFailed(true);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });

    setTimeout(() => {
      setSubmitted(false);
      setSubmitting(false);
      setFailed(true);
    }, 300000);
  };
  return (
    <div className='career-outer'>
      <div className='container jobs-info'>
        <h2 className='section-heading'>Current openings</h2>
        <ControlledExpansionPanels jobDatas={JobData} />
      </div>
      <div className='container main-container'>
        <div className='about'>
          <div className='custom-d-flex justify-content-center'>
            <div className='col-md-12 text-center'>
              <h2 className='section-heading text-uppercase'>Come Work with us!</h2>
              <form onSubmit={handleSubmit}>
                <div className='custom-d-flex'>
                  <div className='form-group width-custom mb-md-0'>
                    <p className='label'>Full Name</p>
                    <input
                      className='form-control'
                      id='fullname'
                      name='fullname'
                      type='text'
                      onChange={(e) => setFullname(e.target.value)}
                      value={fullname}
                      placeholder='Full Name'
                    />
                    <p className='help-block text-danger'></p>
                  </div>
                  <div className='form-group width-custom ml-4 mb-md-0'>
                    <p className='label'>Date of Birth</p>
                    <DatePicker
                      name='dob'
                      placeholderText='Date of Birth'
                      className='form-control dob-form'
                      selected={dob}
                      onChange={(date) => setDob(date)}
                    />

                    <p className='help-block text-danger'></p>
                  </div>
                </div>

                <div className='custom-d-flex'>
                  <div className='form-group width-custom mb-md-0'>
                    <p className='label'>Preferred Job Category</p>
                    <input
                      className='form-control'
                      onChange={(e) => setJobcategory(e.target.value)}
                      name='jobcategory'
                      value={jobcategory}
                      placeholder='Preferred Job Category'
                    />
                    <p className='help-block text-danger'></p>
                  </div>
                  <div className='form-group width-custom ml-4 mb-md-0'>
                    <p className='label'>Preferred Job location</p>
                    <input
                      className='form-control'
                      onChange={(e) => setLocation(e.target.value)}
                      name='location'
                      value={location}
                      placeholder='Preferred Job location'
                    />
                    <p className='help-block text-danger'></p>
                  </div>
                </div>

                <div className='custom-d-flex'>
                  <div className='form-group width-custom mb-md-0'>
                    <p className='label'>Current annual salary</p>
                    <input
                      className='form-control'
                      onChange={(e) => setSalary(e.target.value)}
                      name='salary'
                      value={salary}
                      type='number'
                      placeholder='Current annual salary'
                    />
                    <p className='help-block text-danger'></p>
                  </div>
                  <div className='form-group width-custom ml-4 mb-md-0'>
                    <p className='label'>Mobile number</p>
                    <input
                      className='form-control'
                      onChange={(e) => setMobileno(e.target.value)}
                      name='mobileno'
                      value={mobileno}
                      type='number'
                      placeholder='Mobile number'
                    />
                    <p className='help-block text-danger'></p>
                  </div>
                </div>

                <div className='custom-d-flex'>
                  <div className='form-group width-custom mb-md-0'>
                    <p className='label'>Email ID</p>
                    <input
                      className='form-control'
                      onChange={(e) => setValidEmail(e.target.value)}
                      name='email'
                      value={email}
                      type='email'
                      placeholder='Email ID'
                    />
                    <p className='help-block text-danger'></p>
                  </div>
                  <div className='form-group width-custom ml-4 mb-md-0'>
                    <p className='label'>Language</p>
                    <textarea
                      className='form-control'
                      onChange={(e) => setLanguage(e.target.value)}
                      name='language'
                      value={language}
                      type='textarea'
                      placeholder='Language'
                    />
                    <p className='help-block text-danger'></p>
                  </div>
                </div>

                <div className='custom-d-flex'>
                  <div className='form-group width-custom mb-md-0'>
                    <p className='label'>Permanent Address</p>
                    <textarea
                      className='form-control'
                      onChange={(e) => setPaddress(e.target.value)}
                      name='paddress'
                      value={paddress}
                      type='textarea'
                      placeholder='Permanent Address'
                    />
                    <p className='help-block text-danger'></p>
                  </div>

                  <div className='form-group width-custom ml-4 mb-md-0'>
                    <p className='label'>Professional Qualification</p>
                    <textarea
                      className='form-control'
                      onChange={(e) => setProqualification(e.target.value)}
                      name='proqualification'
                      value={proqualification}
                      type='textarea'
                      placeholder='Professional Qualification'
                    />
                    <p className='help-block text-danger'></p>
                  </div>
                </div>

                <div className='custom-d-flex'>
                  <div className='form-group width-custom mb-md-0'>
                    <p className='label'>Additional Qualification</p>
                    <textarea
                      className='form-control'
                      onChange={(e) => setAddqualification(e.target.value)}
                      name='addqualification'
                      value={addqualification}
                      type='textarea'
                      placeholder='Additional Qualification'
                    />
                    <p className='help-block text-danger'></p>
                  </div>

                  <div className='form-group width-custom ml-4 mb-md-0'>
                    <p className='label'>Key Skills</p>
                    <textarea
                      className='form-control'
                      onChange={(e) => setSkills(e.target.value)}
                      name='skills'
                      value={skills}
                      type='textarea'
                      placeholder='Key Skills'
                    />
                    <p className='help-block text-danger'></p>
                  </div>
                </div>

                <div className='custom-d-flex'>
                  <div className='form-group width-custom mb-md-0'>
                    <p className='label'>Professional Experience</p>
                    <textarea
                      className='form-control'
                      onChange={(e) => setProexp(e.target.value)}
                      name='proexp'
                      value={proexp}
                      type='textarea'
                      placeholder='Professional Experience'
                    />
                    <p className='help-block text-danger'></p>
                  </div>
                  <div className='form-group width-custom ml-4 mb-md-0'>
                    <p className='label'>Any Other Specilization</p>
                    <textarea
                      className='form-control'
                      onChange={(e) => setOtherexp(e.target.value)}
                      name='otherexp'
                      value={otherexp}
                      type='textarea'
                      placeholder='Any Other Specilization'
                    />
                    <p className='help-block text-danger'></p>
                  </div>
                </div>
                <div className='form-group width-custom'>
                  <p className='label'>Upload Your CV</p>
                  <input
                    type='file'
                    className='form-control'
                    onChange={(e) => onFileChange(e)}
                    id='resume'
                    name='resume'
                    placeholder='resume'
                  />
                  <p className='help-block upload-info'>
                    {!fileValid ? 'Supported files .pdf, .docx, .png, .jpg with size less 2mb' : ''}
                  </p>
                </div>

                {submitting ? (
                  <div className='loader'>
                    <div className='lds-ripple text-center'>
                      <div></div>
                      <div></div>
                    </div>
                    <p>Submitting your application</p>
                  </div>
                ) : submitted ? (
                  <div className='success-outer' id='success'>
                    <span>
                      <i className='material-icons'>check</i>
                      <p>Application Submitted</p>
                    </span>
                  </div>
                ) : failed ? (
                  <div className='failed-outer'>
                    <span>
                      <i className='material-icons'>error</i>
                      <p>Submission failed</p>
                    </span>
                  </div>
                ) : (
                  <div className='text-center mt-4 submit-outer'>
                    {fullname &&
                    dob &&
                    jobcategory &&
                    location &&
                    salary &&
                    mobileno &&
                    email &&
                    resume &&
                    emailValid &&
                    proqualification &&
                    paddress &&
                    language &&
                    proexp &&
                    fileValid ? (
                      <button
                        className='btn btn-primary  text-capitalize'
                        id='sendMessageButton'
                        type='submit'
                      >
                        Submit Application
                      </button>
                    ) : (
                      <div>
                        <button
                          className='btn btn-primary  text-capitalize'
                          disabled={true}
                          id='sendMessageButton'
                          type='submit'
                        >
                          Submit Application
                        </button>
                      </div>
                    )}
                  </div>
                )}
                <br></br>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Career;
