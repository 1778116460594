import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import NestedList from '../NestedList/NestedList.component';
import './Dropdown.styles.scss';

export default function Dropdown({ handleServiceItem, isMenuResponsive, scrolltoBottom }) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);

    if (event.target.id) {
      handleServiceItem(event.target.id);
    }
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className='dropdown-container'>
      <div>
        {isMenuResponsive ? (
          <Button
            className='navbar-toggler navbar-toggler-right'
            id='menu-button'
            type='button'
            data-toggle='collapse'
            data-target='#navbarResponsive'
            aria-controls='navbarResponsive'
            aria-expanded='false'
            aria-label='Toggle navigation'
            ref={anchorRef}
            aria-haspopup='true'
            onClick={handleToggle}
          >
            <i className='fa fa-bars'></i>
          </Button>
        ) : (
          <Button
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup='true'
            onClick={handleToggle}
            className='nav-link js-scroll-trigger service-button'
          >
            Services
          </Button>
        )}

        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} id='menu-list-grow' onKeyDown={handleListKeyDown}>
                    {isMenuResponsive ? (
                      <div className='menu-dropdown'>
                        <Link className='dropdown-link' to='/'>
                          <MenuItem onClick={handleClose}>
                            <span className='material-icons'>home</span>
                            <span>Home</span>
                          </MenuItem>
                        </Link>

                        <MenuItem>
                          <NestedList handleClose={handleClose} />
                        </MenuItem>

                        <Link className='dropdown-link' to='/careers'>
                          <MenuItem onClick={handleClose}>
                            <span className='material-icons'>work</span>
                            <span>careers</span>
                          </MenuItem>
                        </Link>

                        <a className='dropdown-link'>
                          <MenuItem
                            onClick={() => {
                              scrolltoBottom();
                              setOpen(false);
                            }}
                          >
                            <span className='material-icons'>contacts</span>
                            <span>Contact</span>
                          </MenuItem>
                        </a>
                        <Link className='dropdown-link' to='/about'>
                          <MenuItem onClick={handleClose}>
                            <span className='material-icons'>info</span>
                            <span>About Us</span>
                          </MenuItem>
                        </Link>
                      </div>
                    ) : (
                      <Link className='dropdown-link' to='/services'>
                        <MenuItem id='1' onClick={handleClose}>
                          Detailed Engineering Solutions
                        </MenuItem>
                        <MenuItem id='2' onClick={handleClose}>
                          Building Information Modeling
                        </MenuItem>
                        <MenuItem id='3' onClick={handleClose}>
                          Project Management Services
                        </MenuItem>
                        <MenuItem id='4' onClick={handleClose}>
                          Traffic Management
                        </MenuItem>
                        <MenuItem id='5' onClick={handleClose}>
                          Underground Utility Survey
                        </MenuItem>
                      </Link>
                    )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}
