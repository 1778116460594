export const JobData = [
    {
        title: "Civil Draughtsman",
        description: "Diploma/ITI – Experience in creating civil and structural drawings, working knowledge in Autodesk products, including Revit, Civil 3D and AutoCAD."
    },
    {
        title: "MEP Draughtsman",
        description: "Diploma/ITI – Experience in creating MEP drawings, working knowledge in Autodesk products, including Revit, Civil 3D and AutoCAD."
    },
    {
        title: "Structural Engineer",
        description: "Civil Engineering Degree – Working knowledge of structural detailing, proficiency in AutoCAD, Revit, Civil 3D."
    },
    {
        title: "BIM Engineer",
        description: "Architectural or Engineering Degree - A minimum of 2 years of professional experience using and supporting Autodesk products, including Revit Architecture, Revit MEP, Revit Structure, Civil 3D, AutoCAD and/or Navisworks."
    },
    {
        title: "Civil Engineer",
        description: "Civil Engineering Degree – Working knowledge in roads and utilities drawings, proficiency in AutoCAD, Revit, Civil 3D."
    },
];