import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import ContactForm from '../ContactForm/ContactForm.component';
import './Footer.styles.scss';

const Footer = forwardRef((props, ref) => (
  <div className='footer-container' ref={ref}>
    <footer className='footer' id='contact'>
      {/* Contact form */}
      <ContactForm />

      <div className='container'>
        <div className='row contact-address'>
          <div className='col-md-6 d-flex flex-column align-items-center'>
            <h2>India</h2>
            <div>
              <div className='d-flex flex-row'>
                <i className='fas fa-map-marker-alt location-marker'></i>
                <div>
                  <p>726, SND Layout,</p>
                  <p>Crosscut Road, Tatabad</p>
                  <p>Coimbatore-641012</p>
                  <p>Tamil Nadu</p>
                </div>
              </div>
              <div className='d-flex flex-row'>
                <i className='fas fa-map-marker-alt location-marker'></i>
                <div>
                  <p>Bangalore,</p>
                  <p>Karnataka</p>
                </div>
              </div>
              <div className='d-flex flex-row'>
                <i className='fas fa-phone location-marker'></i>
                <p className='last-para'>+91 9946112670</p>
              </div>
            </div>
          </div>
          <div className='col-md-6 d-flex flex-column align-items-center'>
            <h2>Middle-east</h2>
            <div>
              <div className='d-flex flex-row'>
                <i className='fas fa-map-marker-alt location-marker'></i>
                <p>Al Reem Tower, Doha - Qatar</p>
              </div>
              <div className='d-flex flex-row'>
                <i className='fas fa-phone location-marker'></i>
                <p>+974 66749801</p>
              </div>
              <div className='d-flex flex-row'>
                <i className='fas fa-phone location-marker'></i>
                <p>+974 50616806</p>
              </div>
              <div className='d-flex flex-row'>
                <i className='fas fa-envelope-square location-marker'></i>
                <p>info@aligninfra.com</p>
              </div>
              <div className='d-flex flex-row'>
                <i className='fas fa-laptop location-marker laptop-icon'></i>
                <p>www.aligninfra.com</p>
              </div>
            </div>
          </div>
        </div>

        <div className='row align-items-center'>
          <div className='col-md-4'>
            <span className='copyright'>Copyright &copy; 2020 Align Infra All Rights Reserved</span>
          </div>
          <div className='col-md-4'>
            <ul className='list-inline social-buttons'>
              <li className='list-inline-item'>
                <a href='#something'>
                  <i className='fa fa-facebook-f'></i>
                </a>
              </li>
              <li className='list-inline-item'>
                <a href='#something'>
                  <i className='fab fa-instagram'></i>
                </a>
              </li>
              <li className='list-inline-item'>
                <a href='#something'>
                  <i className='fab fa-whatsapp'></i>
                </a>
              </li>
              <li className='list-inline-item'>
                <a
                  href='https://www.linkedin.com/company/align-infra-engineering-solutions/about'
                  target='_blank'
                >
                  <i className='fa fa-linkedin'></i>
                </a>
              </li>
            </ul>
          </div>
          <div className='col-md-4'>
            <ul className='list-inline quicklinks'>
              <li className='list-inline-item'>
                <Link to='/privacy'>Privacy Policy</Link>
              </li>

              <li className='list-inline-item'>
                <a
                  id='credits'
                  href='https://www.freepik.com/photos/business'
                  arget='_blank'
                  rel='noreferrer'
                >
                  Image credits: Freepik
                </a>
              </li>

              <li className='list-inline-item'>
                <a
                  id='credits'
                  href='https://instagram.com/haeres_technologies?igshid=enrxl3v5pkvz'
                  target='_blank'
                  rel='noreferrer'
                >
                  Designed & Developed By Haeres Technologies
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  </div>
));

export default Footer;
