import React from 'react';
import './privacyPolicy.css';
const PrivacyPolicy = () => (
    <div>
        <div id="design" className="heading">
                 <h1>Privacy Policy</h1>
         </div>
    <div>
        <div className="container">
            <div className="about">
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <h2 className="section-heading ">Introduction</h2>
                        <p>
                             Align Infra and its operating divisions, subsidiaries, affiliates and branches are sensitive to privacy issues, and it is important to us to protect the information provided to us by our Clients, Suppliers, and potential, current and former Employees. Accordingly, Align Infra provides this privacy and information practices policy to inform you about our information practices, the kinds of information we may collect, how we intend to use and share that information, and how you can correct or change such information (“PrivacyPolicy”). We encourage you to read this Privacy Policy in its entirety before submitting any information. By using Align Infra’s websites, submitting or requesting information, you acknowledge that you understand and agree with the provisions of this Privacy Policy
                        </p>
                    </div>
                </div>
            </div>

            <div className="about">
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <h2 className="section-heading">Collecting Personal Information</h2>
                        <p>
                              We may collect, store and use the following kinds of personal information.
                              Information about your computer and about your visits to and use of this website (including your IP address, geographical location, browser type and version, operating system, referral source, length of visit, page views and website navigation paths).
                              Information that you provide to us when registering with our website including your email address.
                              Information that you provide to us for the purpose of subscribing to our email notifications and/or newsletters (including [your name and email address).
                              Information that you provide to us when using the services on our website, or that is generated in the course of the use of those services (including the timing, frequency and pattern of service use).
                              Information contained in or relating to any communications that you send to us or send through enquiry forms or otherwise on our website (including the communication content and meta data associated with the communication).
                              Any other personal information that you choose to send to us.
                              Before you disclose to us the personal information of another person, you must obtain that person's consent to both the disclosure and the processing of that personal information in accordance with this policy.
                        </p>
                    </div>
                </div>
            </div>

            <div className="about">
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <h2 className="section-heading ">Using Your Personal Information</h2>
                        <p>
                             Personal information submitted to us through our website will be used for the purposes specified in this policy or on the relevant pages of the website.
                             We may use your personal information to:
                                  Administer our website and business.
                                  Personalise our website for you.
                                  Enable your use of the services available on our website.
                                  Send you email notifications that you have specifically requested.
                                  Send you our email newsletter, if you have requested it.
                                  Deal with enquiries and complaints made by or about you relating to our website.
                                  Keep our website secure and prevent fraud.
                                  If you submit personal information for publication on our website, we will publish and otherwise use that information in accordance with the licence you grant to us.
                        </p>
                    </div>
                </div>
            </div>


            <div className="about">
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <h2 className="section-heading">Disclosing Personal Information</h2>
                        <p>
                            We may disclose your personal information to any of our employees, officers, advisers, agents, insofar as reasonably necessary for the purposes set out in this policy.
                            We may disclose your personal information to any member of our group of companies (this means our affiliates and subsidiaries,) insofar as reasonably necessary for the purposes set out in this policy.
                            We may disclose your personal information:
                                 To the extent that we are required to do so by law.
                                 In order to establish, exercise or defend our legal rights (including providing information to others for the purposes of fraud prevention and reducing credit risk).
                                 To the purchaser (or prospective purchaser) of any business or asset that we are (or are contemplating) selling and
                                 To any person who we reasonably believe may apply to a court or other competent authority for disclosure of that personal information where, in our reasonable opinion, such court or authority would be reasonably likely to order disclosure of that personal information.
                                 Except as provided in this policy, we will not provide your personal information to third parties.
                        </p>
                    </div>
                </div>
            </div>


            <div className="about">
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <h2 className="section-heading">International Data Transfers</h2>
                        <p>
                            Information that we collect may be stored and processed in and transferred between any of the countries in which we operate in order to enable us to use the information in accordance with this policy.
                            Personal information that you publish on our website or submit for publication on our website may be available, via the internet, around the world. We cannot prevent the use or misuse of such information by others.
                            You expressly agree to the transfers of personal information described in this Section.
                        </p>
                    </div>
                </div>
            </div>


            <div className="about">
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <h2 className="section-heading ">Retaining Personal Information</h2>
                        <p>
                            This Section sets out our data retention policies and procedure, which are designed to help ensure that we comply with our legal obligations in relation to the retention and deletion of personal information.
                            Personal information that we process for any purpose or purposes shall not be kept for longer than is necessary for that purpose or those purposes.
                        </p>
                    </div>
                </div>
            </div>


            <div className="about">
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <h2 className="section-heading ">Security of your Personal Information</h2>
                        <p>
                            We will take reasonable technical and organisational precautions to prevent the loss, misuse or alteration of your personal information.
                            We will store all the personal information you provide on our secure (password- and firewall-protected) servers.
                            You acknowledge that the transmission of information over the internet is inherently insecure, and we cannot guarantee the security of data sent over the internet.
                        </p>
                    </div>
                </div>
            </div>


            <div className="about">
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <h2 className="section-heading ">Cookies</h2>
                        <p>
                            Like many other websites, Align Infra may employ a cookie, or small piece of computer code that enables Web servers to “identify” visitors, each time an individual initiates a session on the Company’s Sites. A cookie is set in order to identify Data Subjects and tailor our Sites to you. 
                            Cookies do not store any of the Personal Data that is provided to the Align Infra Sites; they are simply identifiers. Data Subjects have the ability to delete cookie files from their own hard drive at any time. However, individuals should be advised that cookies may be necessary to provide access to much of the content and many of the features of Align Infra Sites.
                            This Privacy Policy may be amended and updated periodically and without prior notice to you to reflect changes in our online information practices. When changes are made to this Policy it will be posted to the website and the "last updated" date of this policy will be revised.  Align Infra encourages you to review this Privacy Policy periodically to be informed regarding how we are using and protecting your Personal Data and to be aware of any policy changes. Your continued relationship with Align Infra after the posting or notice of any amended Privacy Policy shall constitute your agreement to be bound by any such changes. Any changes to this Privacy Policy take effect immediately after being posted or as otherwise provided by Align Infra.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
 </div>
); 


export default PrivacyPolicy;