import React from 'react';
import './ServicePanel.styles.scss';
// import Img from '../../assets/img/test.svg';

const ServicePanel = ({ serviceDetails, index }) => {
    if (index === 0) {
        return (
            <div className="container list-main-outer">
                {
                    serviceDetails.details.map(item => (
                        <div className="row">
                            <div className="col-lg-1">
                                {/* <img src={Img} alt="service" /> */}
                            </div>
                            <div className="col-lg-10">
                                <div className="sublist">
                                    <h3>{item.title}</h3>
                                    <ul>
                                        {
                                            item.listitems.map(item => (
                                                <li className="outer-list">{item}</li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        );
    } else {
        return (
            <div className="container list-main-outer">
                <div className="row">
                    <div className="col-lg-1">
                        {/* <img src={Img} alt="service" /> */}
                    </div>
                    <div className="col-lg-10">
                        <div className="sublist">
                            <ul>
                                {
                                    serviceDetails.subtitles.map(item => (
                                        <li className="outer-list">
                                            {item.title}
                                            <ul>
                                                {
                                                    serviceDetails.details[item.id].listitems.map(subitem => (
                                                        <li className="inner-list">{subitem}</li>
                                                    ))
                                                }

                                            </ul>
                                        </li>

                                    ))
                                }
                            </ul>

                        </div>
                    </div>
                </div>
            </div>
        )
    }

}


export default ServicePanel;