import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import { TextField, withStyles, Button, CircularProgress } from '@material-ui/core';
import toast from 'react-hot-toast';
import { emailValidation, normalFieldValidation } from '../../utils/Validations';
import { capitalize } from '../../utils/CustomFunctions';
import './ContactForm.styles.scss';

const StyledTextField = withStyles({
  root: {
    '& label': {
      color: '#000',
      fontSize: '15px',
      fontFamily: "'Lato', sans-serif"
    },
    '& label.Mui-focused': {
      color: 'rgb(3,90,161)'
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#000'
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'rgb(3,90,161)'
    },
    '& .MuiInputBase-input': {
      color: '#000',
      fontSize: '15px',
      fontWeight: '500',
      letterSpacing: '1px'
    },
    '& .MuiInput-underline.Mui-error:after': {
      // Styles for error underline
      borderBottomColor: '#e60000'
    },
    '& .MuiFormLabel-root.Mui-error': {
      // Styles for error label
      color: '#e60000'
    }
  }
})(TextField);

const ContactForm = () => {
  const [fields, setFields] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: '',
    country: '',
    companyName: '',
    projectDescription: '',
    message: ''
  });
  const requiredFields = ['firstName', 'lastName', 'email', 'country', 'projectDescription'];
  const [fieldsError, setFieldsError] = useState({
    firstName: '',
    lastName: '',
    email: '',
    country: '',
    projectDescription: ''
  });
  const [loading, setLoading] = useState(false);

  /**
   * The function `onFieldValueChange` handles the change event for input fields and updates the state
   * with the new field value, while also performing validation based on the field name.
   */
  const onFieldValueChange = (e) => {
    e.persist();
    const value = e.target.value;
    setFields((fields) => ({
      ...fields,
      [e.target.name]: value
    }));
    let error = null;
    switch (e.target.name) {
      case 'firstName':
        error = normalFieldValidation('First name', value);
        break;
      case 'lastName':
        error = normalFieldValidation('Last name', value);
        break;
      case 'email':
        error = emailValidation(value);
        break;
      case 'country':
        error = normalFieldValidation('Country', value);
        break;
      case 'projectDescription':
        error = normalFieldValidation('Project description', value);
        break;
      default:
        break;
    }
    if (error !== null) {
      setFieldsError((prevErrors) => ({ ...prevErrors, [e.target.name]: error }));
    }
  };

  /**
   * The function checks if all required fields are filled and validates the fields for errors before
   * sending an email.
   */
  const sendEmail = () => {
    const isAnyRequiredFieldEmpty = requiredFields.some((field) => fields[field] === '');
    if (Object.values(fieldsError).every((field) => field === '') && !isAnyRequiredFieldEmpty) {
      // Required fields are filled
      setLoading(true);
      try {
        emailjs
          .send(
            process.env.REACT_APP_EMAILJS_SERVICE_ID,
            process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
            {
              user_name: `${capitalize(fields.firstName)} ${capitalize(fields.lastName)}`,
              user_email: `${fields.email}`,
              message: fields.message,
              country: capitalize(fields.country),
              user_phone: fields.phone,
              user_address: fields.address,
              companyName: fields.companyName,
              projectDescription: fields.projectDescription
            },
            process.env.REACT_APP_EMAILJS_PUBLIC_KEY
          )
          .then((result) => {
            setLoading(false);
            toast.success('Your message sent successfully!');
            setFields({
              firstName: '',
              lastName: '',
              email: '',
              phone: '',
              address: '',
              country: '',
              companyName: '',
              projectDescription: '',
              message: ''
            });
          })
          .catch((error) => {
            setLoading(false);
            toast.error('Something went wrong. Please try again.');
          });
      } catch (error) {}
    } else {
      setFieldsError({
        firstName: normalFieldValidation('First name', fields.firstName),
        lastName: normalFieldValidation('Last name', fields.lastName),
        email: emailValidation(fields.email),
        country: normalFieldValidation('Country', fields.country),
        projectDescription: normalFieldValidation('Project description', fields.projectDescription)
      });
    }
  };

  return (
    <div className='contact-form-wrapper'>
      <h2 className='title'>CONTACT US</h2>

      {/* Contact form */}
      <div className='contact-form'>
        {/* First name */}
        <StyledTextField
          label='First Name'
          name='firstName'
          type='text'
          required
          error={fieldsError.firstName !== ''}
          helperText={fieldsError.firstName}
          value={fields.firstName}
          onChange={onFieldValueChange}
          className='half-input-field'
          inputProps={{
            style: { textTransform: 'capitalize' }
          }}
        />

        {/* Last name */}
        <StyledTextField
          label='Last Name'
          name='lastName'
          type='text'
          required
          error={fieldsError.lastName !== ''}
          helperText={fieldsError.lastName}
          value={fields.lastName}
          onChange={onFieldValueChange}
          className='half-input-field'
          inputProps={{
            style: { textTransform: 'capitalize' }
          }}
        />

        {/* Email */}
        <StyledTextField
          label='Your Email'
          type='email'
          name='email'
          required
          error={fieldsError.email !== ''}
          helperText={fieldsError.email}
          value={fields.email}
          onChange={onFieldValueChange}
          className='half-input-field'
        />

        {/* Phone */}
        <StyledTextField
          label='Phone'
          name='phone'
          type='number'
          value={fields.phone}
          onChange={onFieldValueChange}
          className='half-input-field'
        />

        {/* Address */}
        <StyledTextField
          label='Address'
          type='text'
          name='address'
          value={fields.address}
          onChange={onFieldValueChange}
          className='half-input-field'
        />

        {/* Country */}
        <StyledTextField
          label='Country'
          name='country'
          type='text'
          required
          error={fieldsError.country !== ''}
          helperText={fieldsError.country}
          value={fields.country}
          onChange={onFieldValueChange}
          className='half-input-field'
          inputProps={{
            style: { textTransform: 'capitalize' }
          }}
        />

        {/* Company */}
        <StyledTextField
          label='Company Name'
          type='text'
          name='companyName'
          value={fields.companyName}
          onChange={onFieldValueChange}
          className='half-input-field'
        />

        {/* Project description */}
        <StyledTextField
          label='Project Description'
          required
          type='text'
          name='projectDescription'
          value={fields.projectDescription}
          error={fieldsError.projectDescription !== ''}
          helperText={fieldsError.projectDescription}
          onChange={onFieldValueChange}
          className='half-input-field'
        />

        {/* Message */}
        <StyledTextField
          label='Your Message'
          type='text'
          name='message'
          value={fields.message}
          onChange={onFieldValueChange}
          className='full-input-field'
          multiline
        />

        <p className='full-input-field feel-free'>Feel free to reach us at <a href='mailto:sales@aligninfra.com'>sales@aligninfra.com</a></p>

        {/* Submit */}
        <Button variant='contained' className='half-input-field send-button' onClick={sendEmail}>
          {loading ? <CircularProgress size={22} /> : 'Send'}
        </Button>
      </div>
    </div>
  );
};

export default ContactForm;
