import React from 'react';
import { Link } from 'react-router-dom';
import Dropdown from '../Dropdown/Dropdown.component';
import './Navbar.styles.scss';

const Navbar = ({ handleServiceItem, scrolltoBottom, gotoContact, isContactDivInView }) => {
  return (
    <div className='navbar-container'>
      <nav className='navbar navbar-expand-md navbar-dark fixed-top' id='mainNav'>
        <div className='container-fluid dropdown'>
          <div
            className='navbar-toggler navbar-toggler-right'
            id='menu-button'
            type='button'
            data-toggle='collapse'
            data-target='#navbarResponsive'
            aria-controls='navbarResponsive'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            <Dropdown
              isMenuResponsive
              handleServiceItem={handleServiceItem}
              scrolltoBottom={scrolltoBottom}
            />
          </div>

          <div className='collapse navbar-collapse' id='navbarResponsive'>
            <ul className='navbar-nav text-uppercase m-auto' id='navbar-menu-list'>
              <li className='nav-item'>
                <Link className='nav-link js-scroll-trigger' to='/'>
                  Home
                </Link>
              </li>
              <li className='nav-item'>
                <Dropdown handleServiceItem={handleServiceItem} />
              </li>
              <li className='nav-item'>
                <Link className='nav-link js-scroll-trigger' to='/careers'>
                  Careers
                </Link>
              </li>
              <li className='nav-item'>
                <a className='nav-link js-scroll-trigger' onClick={gotoContact}>
                  Contact
                </a>
              </li>
              <li className='nav-item'>
                <Link
                  style={{ whiteSpace: 'nowrap' }}
                  className='nav-link js-scroll-trigger'
                  to='/about'
                >
                  About Us
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      {/* Floating contact button */}
      {!isContactDivInView ? (
        <div className='contact-float-btn' onClick={gotoContact}>
          <p className='contact-float-btn-txt'>Contact our team</p>
        </div>
      ) : null}
    </div>
  );
};

export default Navbar;
